(<template>
  <div class="playback-video">
    <div class="playback-video__video-cont">
      <video v-if="showVideoElem"
             ref="videoElem"
             autoplay
             class="playback-video__video"
             @timeupdate="updateTime($event)"
             @ended="endVideo">
        <source :src="recording.video"
                type="video/mp4">
      </video>
      <div :class="{'playback-video__playback-btn-wrapper--black': !showVideoElem}"
           class="playback-video__playback-btn-wrapper">
        <button v-if="!videoInProgress"
                class="sk-btn sk-btn--default playback-video__playback-btn"
                @click="startVideo">{{ $gettext('Playback test recording') }}</button>
        <button v-if="videoInProgress"
                class="playback-video__stop-btn"
                @click="stopVideo"></button>
      </div>
    </div>
    <div class="playback-video__recording-info-cont">
      <recording-info :recording="recording" />
    </div>
  </div>
</template>)

<script>
  import RecordingInfo from '@/components/test_video/TestVideoRecordingInfo';

  export default {
    components: {
      'recording-info': RecordingInfo
    },
    props: {
      recording: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        videoInProgress: false,
        watchedTime: 0
      };
    },
    computed: {
      showVideoElem() { return this.watchedTime || this.videoInProgress; }
    },
    watch: {
      $route(newVal, oldVal) {
        if (oldVal
          && newVal
          && oldVal.name == newVal.name
          && oldVal.params.id != newVal.params.id) {
          this.videoInProgress = false;
          this.watchedTime = 0;
        }
      }
    },
    methods: {
      updateTime(eve) {
        this.watchedTime = eve.currentTarget.currentTime;
      },
      startVideo() {
        this.videoInProgress = true;
        if (this.$refs.videoElem) {
          this.$refs.videoElem.play();
        }
      },
      stopVideo() {
        if (this.$refs.videoElem) {
          this.$refs.videoElem.pause();
          this.videoInProgress = false;
        }
      },
      endVideo() {
        if (this.$refs.videoElem) {
          this.$refs.videoElem.currentTime = 0;
        }
        this.videoInProgress = false;
      }
    }
  };
</script>

<style scoped>
  .playback-video {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .playback-video__video-cont {
    position: relative;
    display: block;
    height: calc(310px * 0.564171123);
  }

  .playback-video__video {
    display: block;
    width: 100%;
    height: 100%;
    background-color: #000;
  }

  .playback-video__playback-btn-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .playback-video__playback-btn-wrapper--black {
    background-color: #000;
  }

  .playback-video__playback-btn {
    display: inline-block;
    width: auto;
    min-width: 220px;
    max-width: 100%;
  }

  .playback-video__stop-btn {
    position: absolute;
    bottom: 10px;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #f04;
    background-color: var(--color-error);
    transform: translateX(-50%);
  }

  .playback-video__stop-btn::before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background-color: #fff;
  }

  .playback-video__video-cont,
  .playback-video__recording-info-cont {
    width: calc(50% - 15px);
  }

  @media (max-width: 1029px) {
    .playback-video {
      display: block;
    }

    .playback-video__recording-info-cont {
      padding: 20px 15px;
    }

    .playback-video__video-cont {
      height: calc((100vw - 310px) * 0.564171123);
    }

    .playback-video__video-cont,
    .playback-video__recording-info-cont {
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    .playback-video__video-cont {
      height: calc(100vw * 0.564171123);
    }
  }
</style>
