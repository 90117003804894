// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/test_camera_icon.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@assets/imgs/undefined_imgs/test_surroundings_icon.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@assets/imgs/undefined_imgs/test_clothing_icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "\n.tips-imgs__wrapper[data-v-bd12d60e] {\n  display: block;\n  width: 100%;\n  padding: 20px;\n  padding-bottom: 50px;\n  background-color: #e0f5f5;\n}\n.tips-imgs__title[data-v-bd12d60e] {\n  display: block;\n  width: 100%;\n  margin-bottom: 10px;\n  font-weight: bold;\n}\n.tips-imgs__imgs-cont[data-v-bd12d60e] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: distribute;\n      justify-content: space-around;\n  width: 100%;\n}\n.tips-imgs__img-cont[data-v-bd12d60e] {\n  display: block;\n  width: 170px;\n  max-width: 33.3333333%;\n  text-align: center;\n}\n.tips-imgs__img[data-v-bd12d60e] {\n  display: block;\n  width: 100%;\n  height: 100px;\n  margin-bottom: 10px;\n  background-position: 50% 50%;\n  background-size: contain;\n  background-repeat: no-repeat;\n}\n.tips-imgs__img--camera[data-v-bd12d60e] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.tips-imgs__img--surroundings[data-v-bd12d60e] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.tips-imgs__img--clothing[data-v-bd12d60e] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n.tips-imgs__img-title[data-v-bd12d60e] {\n  display: block;\n  width: 100%;\n  margin-bottom: 10px;\n  font-weight: bold;\n}\n@media (max-width: 767px) {\n.tips-imgs__wrapper[data-v-bd12d60e] {\n    padding: 15px;\n    padding-bottom: 15px;\n}\n}\n@media (max-width: 550px) {\n.tips-imgs__imgs-cont[data-v-bd12d60e] {\n    -webkit-box-orient: vertical;\n    -webkit-box-direction: normal;\n        -ms-flex-direction: column;\n            flex-direction: column;\n    -webkit-box-align: center;\n        -ms-flex-align: center;\n            align-items: center;\n}\n.tips-imgs__img-cont[data-v-bd12d60e] {\n    width: 100%;\n    max-width: initial;\n    margin-bottom: 40px;\n}\n.tips-imgs__img[data-v-bd12d60e] {\n    width: 170px;\n    margin-right: auto;\n    margin-left: auto;\n}\n}\n", ""]);
// Exports
module.exports = exports;
