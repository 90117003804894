(<template>
  <div class="expl-text">
    <h3 class="expl-text__title">{{ $gettext('New test recording') }}</h3>
    <ol class="expl-text__list">
      <li class="expl-text__item">{{ $gettext('The sound and video test require that you speak 2 sentences on video, so our platform can record it and play it back to you.') }}
        <ul class="expl-text__inner-list">
          <li v-if="userIsInterpreter"
              class="expl-text__inner-list-item">{{ $gettext('Please state your name and the languages that you speak') }}</li>
          <li v-if="userIsNotInterpreter"
              class="expl-text__inner-list-item">{{ $gettext('Please say something so we can record your audio as well.') }}</li>
          <li class="expl-text__inner-list-item">{{ $gettext('The recording will stop when you finish the call.') }}</li>
          <li class="expl-text__inner-list-item">{{ $gettext('You decide if your microphone, camera and speakers are working well enough by listening to your own recording.') }}</li>
        </ul>
      </li>
      <li v-if="userIsInterpreter"
          class="expl-text__item">{{ $gettext('You can delete test recordings anytime, but you must have at least one saved test result to apply for video jobs.') }}</li>
      <li v-if="userIsNotInterpreter"
          class="expl-text__item">{{ $gettext('You can delete test recordings anytime.') }}</li>
      <li class="expl-text__item">{{ $gettext('Please keep your browser and hardware updated for video sessions.') }}</li>
    </ol>
  </div>
</template>)

<script>
  import {mapGetters} from 'vuex';

  export default {
    computed: {
      ...mapGetters('UserInfoStore', ['userIsInterpreter', 'userIsNotInterpreter'])
    }
  };
</script>

<style scoped>
  .expl-text {
    display: block;
    width: 100%;
    margin-bottom: 20px;
  }

  .expl-text__title {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 18px;
  }

  .expl-text__list {
    display: block;
    width: 100%;
    padding-left: 20px;
    counter-reset: item;
  }

  .expl-text__item {
    position: relative;
    display: block;
    padding-bottom: 15px;
  }

  .expl-text__item::before {
    content: counter(item) ". ";
    position: absolute;
    left: -20px;
    display: inline-block;
    width: auto;
    font-weight: bold;
    counter-increment: item;
  }

  .expl-text__inner-list {
    display: block;
    width: calc(100% - 25px);
    margin-left: 25px;
    list-style-type: initial;
  }
</style>
