(<template>
  <section class="record-view">
    <p class="record-view__note">{{ $gettext('Click "Allow" when your browser asks to share your camera and microphone') }}</p>
    <div class="record-view__title-wrapper">
      <button class="record-view__back-btn"
              @click="goBack">{{ $gettext('Back') }}</button>
      <h3 class="record-view__title"><b>{{ deviceString }}</b> {{ dateString }}</h3>
    </div>
    <div class="record-view__video-wrapper">
      <div id="publisher"
           class="record-view__video"></div>
    </div>
    <div class="record-view__btn-wrapper">
      <button v-if="!testStarted"
              id="start"
              :class="[actionBtnClass]"
              class="sk-btn record-view__btn"
              @click="handleStart">{{ actionBtnText }}</button>
      <button v-if="testStarted"
              :class="[actionBtnClass]"
              class="sk-btn record-view__btn"
              @click="stopRecording">{{ actionBtnText }}</button>
    </div>
  </section>
</template>)

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  import helpers from '@/helpers';

  export default {
    data() {
      return {
        testStarted: false,
        session: '',
        publisher: '',
        archiveID: ''
      };
    },
    computed: {
      ...mapState('TTTestVideoStore', ['sessionData']),
      recordID() { return this.sessionData.id; },
      actionBtnText() { return this.testStarted ? this.$gettext('Stop') : this.$gettext('Start'); },
      actionBtnClass() { return this.testStarted ? 'sk-btn--full-red' : 'sk-btn--default'; },
      deviceString() { return helpers.detectDevice(this); },
      dateString() {
        const nowMoment = this.$moment();
        return `${nowMoment.year()} ${this.$helperData.getMonthNames(this)[nowMoment.month()].slice(0, 3)} ${nowMoment.format('DD')} - ${nowMoment.format('HH:mm')}`;
      }
    },
    methods: {
      ...mapActions('TTTestVideoStore', ['startTest', 'endTest']),
      ...mapMutations('TTTestVideoStore', ['startPageProgress', 'stopPageProgress', 'clearSessionData', 'setWantToStart']),
      goBack() { this.setWantToStart(false); },
      handlePermisiionsFailure(text) {
        return () => {
          this.goBack();
          this.$store.commit('InfoModalStore/setInfoModal', {text});
        };
      },
      initializeSession() {
        this.session = window.OT ? window.OT.initSession(this.sessionData.apiKey, this.sessionData.sessionId) : '';
        if (this.session) {
          this.session.on('archiveStarted', () => {
            this.stopPageProgress();
          });
          this.session.on('archiveStopped', () => {
            this.startPageProgress();
          });
          this.session.on('sessionConnected', () => {
            this.stopPageProgress();
          });
          this.session.on('streamCreated', (event) => {
            this.session.subscribe(event.stream, 'subscribers', {insertMode: 'append'});
          });
          // Connect to the session
          this.session.connect(this.sessionData.token, (error) => {
            // If the connection is successful, initialize a publisher and publish to the session
            if (!error) {
              const publisherOptions = {
                insertMode: 'append',
                width: '100%',
                height: '100%'
              };
              this.publisher = window.OT ? window.OT.initPublisher('publisher', publisherOptions, () => {
                this.session.publish(this.publisher);
              }) : '';
              this.publisher.on({accessDenied: this.handlePermisiionsFailure(this.$gettext('You have to allow camera and microphone to record videos'))});
            }
          });
        }
      },
      handleStart() {
        this.startPageProgress();
        this.startTest().then((data) => {
          if (data && data.videoTest) {
            this.archiveID = data.videoTest.id || '';
          }
          this.testStarted = true;
          this.stopPageProgress();
        });
      },
      stopRecording() {
        const deviceArr = this.deviceString.split(', ');
        const obj = {browser: deviceArr[0] || '', device: deviceArr[1] || ''};
        this.startPageProgress();
        this.endTest({id: this.archiveID, obj}).then(() => {
          this.testStarted = false;
          this.setWantToStart(false);
          this.stopPageProgress();
        });
      },
      endSession(next) {
        if (this.session) this.session.disconnect();
        this.clearSessionData();
        if (next) next();
      }
    },
    mounted() {
      if (!window.navigator.mediaDevices) {
        this.handlePermisiionsFailure(this.$gettext('Your device is not supported to record videos. Please use another device'));
        return;
      }
      this.initializeSession();
      this.startPageProgress();
    },
    beforeDestroy() {
      this.endSession();
    },
    beforeRouteLeave(to, from, next) {
      this.endSession(next);
    }
  };
</script>

<style scoped>
  .record-view {
    display: block;
    width: 100%;
  }

  .record-view__note {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #fff5d1;
  }

  .record-view__title-wrapper {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
  }

  .record-view__back-btn {
    position: relative;
    flex-shrink: 0;
    padding-left: 28px;
    font-size: 12px;
  }

  .record-view__back-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 28px;
    height: 100%;
    background-image: url(~@assets/imgs/arrows/big_arrow_to_left.svg);
    background-position: 95% 50%;
    background-size: 10px auto;
    background-repeat: no-repeat;
  }

  .record-view__title {
    display: inline-block;
    padding: 10px 20px;
    font-weight: normal;
    font-size: 18px;
  }

  .record-view__video-wrapper {
    display: flex;
    flex-grow: 1;
    width: 100%;
    height: calc(100vh * 0.564171123);
  }

  .record-view__video {
    display: block;
    width: 100%;
    height: 100%;
    background-color: #000;
  }

  .record-view__btn-wrapper {
    position: relative;
    z-index: 10;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: -75px;
    padding: 20px;
  }

  .record-view__btn {
    display: inline-block;
    width: auto;
    min-width: 200px;
    max-width: 100%;
  }

  @media (max-width: 1029px) {
    .record-view__video-wrapper {
      height: calc(100vw * 0.564171123);
    }
  }
</style>
