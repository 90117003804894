(<template>
  <section class="results-list">
    <h3 class="results-list__title">{{ $gettext('Recorded tests') }}</h3>
    <recording v-for="recording in recordingsList"
               :key="recording.id"
               :recording="recording"
               @delete="handleDelete" />
    <div v-if="!unsupportedDevice"
         class="results-list__start-test-btn-wrapper">
      <button class="sk-btn sk-btn--default results-list__start-test-btn"
              @click="startNewTest">{{ $gettext('Start new test') }}</button>
    </div>
  </section>
</template>)

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  import OneResult from '@/components/test_video/TestVideoOneResult';

  export default {
    components: {
      recording: OneResult
    },
    computed: {
      ...mapState('TTTestVideoStore', ['recordingsList']),
      unsupportedDevice() { return this.$store.state.TTTestVideoStore.unsupportedDevice; }
    },
    methods: {
      ...mapActions('TTTestVideoStore', ['deleteRecording', 'getRecordings', 'createSession']),
      ...mapMutations('TTTestVideoStore', ['startPageProgress', 'stopPageProgress', 'setWantToStart']),
      handleDelete(id) {
        this.startPageProgress();
        this.deleteRecording(id).then(() => {
          this.getRecordings().then(() => {
            this.stopPageProgress();
            if (id == this.$route.params.id) {
              const prefix = this.$store.getters['UserInfoStore/userIsInterpreter'] ? 'Server' : 'Buyer';
              const route = this.$makeRoute({name: `${prefix}AllTests`});
              this.$router.push(route);
            }
          });
        });
      },
      startNewTest() {
        this.startPageProgress();
        this.createSession().then(() => {
          this.setWantToStart(true);
          this.stopPageProgress();
        });
      }
    }
  };
</script>

<style scoped>
  .results-list {
    display: block;
    width: 100%;
    padding: 20px 10px;
  }

  .results-list__title {
    display: block;
    width: 100%;
    margin: 15px 0;
    font-weight: bold;
    font-size: 18px;
  }

  .results-list__start-test-btn-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 15px;
  }

  .results-list__start-test-btn {
    display: inline-block;
    width: auto;
    min-width: 220px;
    max-width: 100%;
  }

  @media (max-width: 767px) {
    .results-list {
      padding: 15px;
    }
  }
</style>
