(<template>
  <div class="video-test-poll">
    <div v-if="inProgress"
         class="video-test-poll__progress-wrapper">
      <p>{{ $gettext('The video file is now being processed. Please wait or return later to see the saved video.') }}</p>
      <div class="video-test-poll__progress is-progress-bar in-progress"></div>
    </div>
    <h3 class="video-test-poll__title"><b>{{ `#${recordingID} | ${browser}, ${hardware}` }}</b> {{ `${date} – ${time}` }}</h3>
    <playback :recording="recording" />
    <div class="video-test-poll__poll-wrapper">
      <h4 class="video-test-poll__poll-title">{{ $gettext('After watching the video above, please answer these questions:') }}</h4>
      <poll-questions :questions="questions"
                      @answered="setPollAnswer" />
    </div>
    <div class="video-test-poll__submit-btns-wrapper">
      <button class="sk-btn sk-btn--white video-test-poll__submit-btn"
              @click="deleteRecording(recording.id)">{{ $gettext('Delete test') }}</button>
      <button :disabled="disabledSaving"
              class="sk-btn sk-btn--default video-test-poll__submit-btn"
              @click="handleSave(recording.id)">{{ $gettext('Save test') }}</button>
    </div>
  </div>
</template>)

<script>
  import {mapState, mapMutations, mapActions} from 'vuex';
  import PollQuestions from '@/components/test_video/TestVideoPollQuestions';
  import Playback from '@/components/test_video/TestVideoPlayback';

  export default {
    components: {
      playback: Playback,
      'poll-questions': PollQuestions
    },
    props: {
      recording: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapState('TTTestVideoStore', ['pollAnswers']),
      questions() {
        return [
          {id: 'voice_works', text: this.$gettext('Can you hear yourself properly?'), value: this.pollAnswers.voice_works},
          {id: 'video_works', text: this.$gettext('Can you see yourself properly?'), value: this.pollAnswers.video_works}
        ];
      },
      inProgress() { return !this.recording.video; },
      disabledSaving() { return this.pollAnswers.voice_works === '' || this.pollAnswers.video_works === ''; },
      browser() { return this.recording.browser; },
      hardware() { return this.recording.device; },
      recordingID() { return this.recording.id; },
      date() {
        const rMoment = this.$moment(this.recording.date);
        return `${rMoment.year()} ${this.$helperData.getMonthNames(this)[rMoment.month()].slice(0, 3)} ${rMoment.format('DD')}`;
      },
      time() {
        const rMoment = this.$moment(this.recording.date);
        return `${rMoment.format('HH:mm')}`;
      }
    },
    methods: {
      ...mapMutations('TTTestVideoStore', ['setPollAnswer', 'startPageProgress', 'stopPageProgress']),
      ...mapActions('TTTestVideoStore', ['savePoll', 'deleteRecording']),
      handleSave(id) {
        this.startPageProgress();
        this.savePoll(id).then(() => {
          this.stopPageProgress();
        });
      }
    }
  };
</script>

<style>
  .video-test-poll__progress.is-progress-bar::after {
    background-color: transparent;
  }
</style>

<style scoped>
  .video-test-poll {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 20px;
    padding-bottom: 20px;
    background-color: #fff;
  }

  .video-test-poll__progress-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.85);
    font-size: 18px;
    text-align: center;
  }

  .video-test-poll__progress {
    display: block;
    width: 100%;
    height: 100px;
  }

  .video-test-poll__title {
    display: block;
    flex-shrink: 0;
    width: 100%;
    margin-bottom: 15px;
    font-weight: normal;
    font-size: 18px;
  }

  .video-test-poll__playback-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .video-test-poll__video-cont {
    position: relative;
    display: block;
    height: calc(310px * 0.564171123);
  }

  .video-test-poll__video {
    display: block;
    width: 100%;
    height: 100%;
    background-color: #000;
  }

  .video-test-poll__playback-btn-wrapper {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    transform: translateY(-50%);
  }

  .video-test-poll__playback-btn {
    display: inline-block;
    width: auto;
    min-width: 220px;
    max-width: 100%;
  }

  .video-test-poll__video-cont,
  .video-test-poll__recording-info-cont {
    width: calc(50% - 15px);
  }

  .video-test-poll__poll-wrapper {
    display: block;
    width: 100%;
    margin: 20px 0;
  }

  .video-test-poll__poll-title {
    display: block;
    width: 100%;
    margin-bottom: 15px;
    font-size: 12px;
  }

  .video-test-poll__submit-btns-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: auto;
  }

  .video-test-poll__submit-btn {
    display: inline-block;
    width: auto;
    min-width: 100px;
  }

  .video-test-poll__submit-btn:first-child {
    margin-right: 20px;
  }

  @media (max-width: 1029px) {
    .video-test-poll {
      padding: 0;
    }

    .video-test-poll__title {
      margin-bottom: 0;
      padding: 10px 15px;
    }

    .video-test-poll__playback-wrapper {
      display: block;
    }

    .video-test-poll__recording-info-cont {
      padding: 20px 15px;
    }

    .video-test-poll__video-cont {
      height: calc((100vw - 310px) * 0.564171123);
    }

    .video-test-poll__video-cont,
    .video-test-poll__recording-info-cont {
      width: 100%;
    }

    .video-test-poll__poll-wrapper {
      margin-top: 0;
      padding: 20px 15px;
    }

    .video-test-poll__submit-btns-wrapper {
      margin-top: 0;
      padding: 20px 15px 25px;
    }
  }

  @media (max-width: 767px) {
    .video-test-poll__video-cont {
      height: calc(100vw * 0.564171123);
    }
  }
</style>
