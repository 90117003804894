(<template>
  <section class="start-page-view">
    <p v-if="unsupportedDevice"
       class="start-page-view__note">{{ $gettext('You are using unsupported device') }}</p>
    <tips-imgs />
    <section class="start-page-view__start-test-cont">
      <expl-text />
      <div v-if="!unsupportedDevice"
           class="start-page-view__start-test-btn-wrapper">
        <button class="sk-btn sk-btn--default start-page-view__start-test-btn"
                @click="startNewTest">{{ $gettext('Start new test') }}</button>
      </div>
    </section>
  </section>
</template>)

<script>
  import {mapMutations, mapActions} from 'vuex';
  import ExplText from '@/components/test_video/TestVideoExplanationText';
  import TipsImgs from '@/components/test_video/TestVideoTipsImgs';

  export default {
    components: {
      'expl-text': ExplText,
      'tips-imgs': TipsImgs
    },
    computed: {
      unsupportedDevice() { return this.$store.state.TTTestVideoStore.unsupportedDevice; }
    },
    methods: {
      ...mapMutations('TTTestVideoStore', ['startPageProgress', 'stopPageProgress', 'setWantToStart']),
      ...mapActions('TTTestVideoStore', ['createSession']),
      startNewTest() {
        this.startPageProgress();
        this.createSession().then(() => {
          this.setWantToStart(true);
          this.stopPageProgress();
        });
      }
    }
  };
</script>

<style scoped>
  .start-page-view {
    display: block;
    flex-grow: 1;
    width: calc(100% - 280px);
  }

  .start-page-view__note {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #fff5d1;
  }

  .start-page-view__start-test-cont {
    display: block;
    width: 100%;
    padding: 20px;
    background-color: #fff;
  }

  .start-page-view__start-test-btn-wrapper {
    display: block;
    width: 100%;
  }

  .start-page-view__start-test-btn {
    display: inline-block;
    width: auto;
    min-width: 220px;
    max-width: 100%;
  }

  @media (max-width: 767px) {
    .start-page-view__start-test-cont {
      padding: 15px;
    }
  }
</style>
