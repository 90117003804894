(<template>
  <div class="recording-info__table">
    <div v-for="row in rows"
         :key="row.label"
         class="recording-info__row">
      <div class="recording-info__cell">{{ row.label }}</div>
      <div :class="row.classes"
           class="recording-info__cell"><b>{{ row.value }}</b></div>
    </div>
  </div>
</template>)

<script>
  export default {
    props: {
      recording: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      rows() {
        return [
          {label: this.$gettext('Browser:'), value: this.browser},
          {label: this.$gettext('Hardware:'), value: this.hardware},
          {label: this.$gettext('Date:'), value: this.date},
          {label: this.$gettext('Time:'), value: this.time},
          {label: this.$gettext('Recorded:'), value: this.$gettext('YES')},
          {label: this.$gettext('Duration:'), value: this.duration},
          {label: this.$gettext('Result:'), value: this.status, classes: [this.status ? `recording-info__cell--${this.status}` : '']}
        ];
      },
      browser() { return this.recording.browser; },
      hardware() { return this.recording.device; },
      date() {
        const rMoment = this.$moment(this.recording.date);
        return `${rMoment.year()} ${this.$helperData.getMonthNames(this)[rMoment.month()].slice(0, 3)} ${rMoment.format('DD')}`;
      },
      time() {
        const rMoment = this.$moment(this.recording.date);
        return `${rMoment.format('HH:mm')}`;
      },
      duration() {
        const template = this.recording.duration > 60 ? this.$gettext('%{minutes} m %{seconds} s') : this.$gettext('%{seconds} s');
        const minutes = parseInt(this.recording.duration / 60);
        const seconds = this.recording.duration - minutes;
        return this.$gettextInterpolate(template, {minutes, seconds});
      },
      status() {
        if (this.recording.voiceWorks === false
          || this.recording.videoWorks === false) {
          return this.$gettext('Failed');
        } else if (this.recording.voiceWorks !== null
          && this.recording.voiceWorks !== undefined
          && this.recording.videoWorks !== null
          && this.recording.videoWorks !== undefined) {
          return this.$gettext('Passed');
        }
        return this.$gettext('Pending');
      }
    }
  };
</script>

<style scoped>
  .recording-info__table {
    display: table;
    width: 100%;
  }

  .recording-info__row {
    display: table-row;
  }

  .recording-info__cell {
    display: table-cell;
    padding: 1px 0;
  }

  .recording-info__cell--Pending {
    color: #692a9d;
  }

  .recording-info__cell--Passed {
    color: #ff5b24;
    color: var(--color-secondary);
  }

  .recording-info__cell--Failed {
    color: #f04;
    color: var(--color-error);
  }
</style>
