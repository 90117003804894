(<template>
  <section class="tips-imgs__wrapper">
    <h3 class="tips-imgs__title">{{ $gettext('Good video practice') }}</h3>
    <div class="tips-imgs__imgs-cont">
      <div class="tips-imgs__img-cont">
        <div class="tips-imgs__img tips-imgs__img--camera"></div>
        <h4 class="tips-imgs__img-title">{{ $gettext('Camera angle') }}</h4>
        <p class="tips-imgs__img-text">{{ $gettext('Make sure that your web camera is pointing at you') }}</p>
      </div>
      <div class="tips-imgs__img-cont">
        <div class="tips-imgs__img tips-imgs__img--surroundings"></div>
        <h4 class="tips-imgs__img-title">{{ $gettext('Surroundings') }}</h4>
        <p class="tips-imgs__img-text">{{ $gettext('Make sure that your surroundings are tidy and professional') }}</p>
      </div>
      <div class="tips-imgs__img-cont">
        <div class="tips-imgs__img tips-imgs__img--clothing"></div>
        <h4 class="tips-imgs__img-title">{{ $gettext('Clothing') }}</h4>
        <p class="tips-imgs__img-text">{{ $gettext('Make sure that your clothing is appropriate for screen use') }}</p>
      </div>
    </div>
  </section>
</template>)

<script>
  export default {};
</script>

<style scoped>
  .tips-imgs__wrapper {
    display: block;
    width: 100%;
    padding: 20px;
    padding-bottom: 50px;
    background-color: #e0f5f5;
  }

  .tips-imgs__title {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    font-weight: bold;
  }

  .tips-imgs__imgs-cont {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  .tips-imgs__img-cont {
    display: block;
    width: 170px;
    max-width: 33.3333333%;
    text-align: center;
  }

  .tips-imgs__img {
    display: block;
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .tips-imgs__img--camera {
    background-image: url(~@assets/imgs/undefined_imgs/test_camera_icon.svg);
  }

  .tips-imgs__img--surroundings {
    background-image: url(~@assets/imgs/undefined_imgs/test_surroundings_icon.svg);
  }

  .tips-imgs__img--clothing {
    background-image: url(~@assets/imgs/undefined_imgs/test_clothing_icon.svg);
  }

  .tips-imgs__img-title {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    font-weight: bold;
  }

  @media (max-width: 767px) {
    .tips-imgs__wrapper {
      padding: 15px;
      padding-bottom: 15px;
    }
  }

  @media (max-width: 550px) {
    .tips-imgs__imgs-cont {
      flex-direction: column;
      align-items: center;
    }

    .tips-imgs__img-cont {
      width: 100%;
      max-width: initial;
      margin-bottom: 40px;
    }

    .tips-imgs__img {
      width: 170px;
      margin-right: auto;
      margin-left: auto;
    }
  }
</style>
