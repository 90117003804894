(<template>
  <div class="poll-questions">
    <div v-for="(question, index) in questions"
         :key="index"
         class="poll-questions__row">
      <div class="poll-questions__cell">{{ `${index + 1}. ${question.text}` }}</div>
      <div class="poll-questions__cell">
        <div class="poll-questions__answer-btns-wrapper">
          <button :class="[getBtnClass(question, 'yes')]"
                  class="sk-btn poll-questions__answer-btn"
                  @click="$emit('answered', {id: question.id, value: true})">{{ $gettext('Yes') }}</button>
          <button :class="[getBtnClass(question, 'no')]"
                  class="sk-btn poll-questions__answer-btn"
                  @click="$emit('answered', {id: question.id, value: false})">{{ $gettext('No') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>)

<script>
  export default {
    props: {
      questions: {
        type: Array,
        default: () => []
      }
    },
    methods: {
      getBtnClass(question, answ) {
        if ((question.value === true && answ == 'yes')
          || (question.value === false && answ == 'no')) {
          return 'sk-btn--default';
        }
        return 'sk-btn--white';
      }
    }
  };
</script>

<style scoped>
  .poll-questions {
    display: table;
  }

  .poll-questions__row {
    display: table-row;
  }

  .poll-questions__cell {
    display: table-cell;
  }

  .poll-questions__cell:first-child {
    width: 380px;
    padding-bottom: 5px;
    font-size: 12px;
  }

  .poll-questions__answer-btns-wrapper {
    display: flex;
  }

  .poll-questions__answer-btn {
    display: inline-block;
    width: auto;
    min-width: 45px;
    height: 20px;
    line-height: 18px;
  }

  .poll-questions__answer-btn:first-child {
    margin-right: 5px;
  }

  @media (max-width: 1029px) {
    .poll-questions,
    .poll-questions__row,
    .poll-questions__cell {
      display: block;
    }

    .poll-questions__row {
      margin-bottom: 10px;
    }

    .poll-questions__cell:first-child {
      width: 100%;
      padding: 0;
      padding-bottom: 5px;
    }

    .poll-questions__answer-btn {
      min-width: 60px;
      height: 35px;
      line-height: 33px;
    }

    .poll-questions__answer-btn:first-child {
      margin-right: 15px;
    }
  }
</style>
