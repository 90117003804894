(<template>
  <div :class="{'in-progress': pageProgress}"
       class="test-video is-progress-bar">
    <component :is="activeView" />
  </div>
</template>)

<script>
  import {mapState} from 'vuex';
  import StartPageView from '@/components/test_video/page_views/TestVideoStartPageView';
  import RecordPageView from '@/components/test_video/page_views/TestVideoRecordPageView';
  import OverviewPageView from '@/components/test_video/page_views/TestVideoOwerviewPageView';

  export default {
    asyncData({store}) {
      let promisesArr = [];
      if (!store.state.TTTestVideoStore.recordingsList) {
        promisesArr = [...promisesArr, store.dispatch('TTTestVideoStore/getRecordings')];
      }
      return promisesArr ? Promise.all(promisesArr) : promisesArr;
    },
    components: {
      'start-page-view': StartPageView,
      'record-page-view': RecordPageView,
      'owerview-page-view': OverviewPageView
    },
    data() {
      return {
        testText: ''
      };
    },
    computed: {
      ...mapState('TTTestVideoStore', [
        'pageProgress',
        'wantToStart',
        'recordingsList'
      ]),
      activeView() {
        if (this.wantToStart) {
          return 'record-page-view';
        } else if (!this.recordingsList || !this.recordingsList.length) {
          return 'start-page-view';
        } else if (this.recordingsList && this.recordingsList.length) {
          return 'owerview-page-view';
        }
      }
    },
    mounted() {
      if (!window.navigator.mediaDevices) {
        this.$store.commit('TTTestVideoStore/setUnsupportedDevice');
      }
    },
    beforeRouteLeave(to, from, next) {
      this.$store.commit('TTTestVideoStore/clearStore');
      next();
    }
  };
</script>

<style scoped>
  .test {
    position: fixed;
    background-color: yellow;
    font-size: 40px;
  }

  .test-video {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1030px;

    /* height: calc(100vh - 110px); */
    margin: 0 auto;
    padding: 20px 15px 30px;

    /* overflow: auto; */
  }

  .test-video__video-wrapper {
    display: block;
    width: calc(100% - 280px);
  }

  .test-video__results-wrapper {
    display: block;
    flex-shrink: 0;
    width: 270px;
    background-color: #fff;
  }

  @media (max-width: 1029px) {
    .test-video {
      height: initial;
    }
  }

  @media (max-width: 767px) {
    .test-video {
      padding: 0;
    }

    .test-video__video-wrapper,
    .test-video__results-wrapper {
      width: 100%;
    }
  }
</style>
