(<template>
  <div class="owerview-view">
    <tips-imgs />
    <section class="owerview-view__results-wrapper">
      <res-list />
    </section>
    <section class="owerview-view__video-wrapper">
      <poll :recording="oneRecordingObj" />
    </section>
  </div>
</template>)

<script>
  import {mapState} from 'vuex';
  import TipsImgs from '@/components/test_video/TestVideoTipsImgs';
  import ResultsList from '@/components/test_video/TestVideoResultsList';
  import Poll from '@/components/test_video/TestVideoPoll';

  export default {
    components: {
      poll: Poll,
      'res-list': ResultsList,
      'tips-imgs': TipsImgs
    },
    computed: {
      ...mapState('TTTestVideoStore', ['oneRecording']),
      oneRecordingObj() {
        return this.oneRecording || {};
      }
    },
    beforeRouteUpdate(to, from, next) {
      this.$store.commit('TTTestVideoStore/clearPoll');
      next();
    }
  };
</script>

<style scoped>
  .owerview-view {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }

  .owerview-view__video-wrapper {
    display: flex;
    width: calc(100% - 280px);
  }

  .owerview-view__results-wrapper {
    display: block;
    flex-shrink: 0;
    width: 270px;

    /* max-height: calc(100% - 300px); */

    /* overflow: auto; */
    background-color: #fff;
  }

  .owerview-view__start-test-cont {
    display: block;
    width: 100%;
    padding: 20px;
    background-color: #fff;
  }

  .owerview-view__start-test-btn-wrapper {
    display: block;
    width: 100%;
  }

  .owerview-view__start-test-btn {
    display: inline-block;
    width: auto;
    min-width: 220px;
    max-width: 100%;
  }

  @media (max-width: 767px) {
    .owerview-view {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      padding: 0;
    }

    .owerview-view__video-wrapper,
    .owerview-view__results-wrapper {
      width: 100%;
    }

    .owerview-view__video-wrapper {
      order: 2;
    }

    .owerview-view__results-wrapper {
      order: 3;
    }

    .owerview-view__start-test-cont {
      padding: 15px;
    }
  }
</style>
